<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent width="50em" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Excel Upload
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Excel Upload</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-file-input
                  v-model="input.excel"
                  accept=".xlsx"
                  placeholder="Datei auswählen"
                  label="datei.xlxs"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">
            Schließen
          </v-btn>
          <v-btn color="primary" text @click="uploadFile">
            Hochladen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    dialog: false,
  }),
  computed: {
      ...mapGetters("file", ["input"]),
  },
  methods: {
      ...mapActions("file", ["upload"]),
      uploadFile() {
          this.upload(this.input);
          this.dialog = false;
           this.$swal('Datei wurde hochgeladen.');
      },
  }
};
</script>