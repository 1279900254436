<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col col="6" offset="0">
          <h1>{{ name }}</h1>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="getDriversLicenseData"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
    <FormDialog></FormDialog>
    <v-btn color="primary" @click="reloadPage">
  <v-icon>  {{ icons.mdiReload }}</v-icon>
</v-btn>
          <v-dialog v-model="dialog" fullscreen hide-overlay>
            <v-card>
              <v-toolbar>
                <v-btn icon @click.native="close">
                  <v-icon>{{ icons.mdiClose }}</v-icon>
                </v-btn>
                <v-toolbar-title>Medien zum Vorgang: {{ selectedName }} </v-toolbar-title>
                <v-row class="justify-end">
                  <v-btn
                    class="success mr-10"
                    @click="changeVerification(true)"
                  >
                    Ist validiert
                  </v-btn>
                  <v-btn class="error" @click="changeVerification(false)">
                    Nicht validiert
                  </v-btn>
                </v-row>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-spacer></v-spacer>
              <v-divider></v-divider>
              
              <v-container class="my-5">
                <div class="mt-5">
                  <v-row class="justify-center">
                    <v-col
                      v-for="(src, i) in imageSources"
                      :key="i"
                      class="d-flex child-flex mr-16"
                      cols="4"
                    >
                      <div
                        v-if="src.includes('.mp4')"
                        @click="selectedItem = src"
                      >
                        <video
                          :key="src"
                          width="640"
                          height="480"
                          controls
                          aspect-ratio="1.7778"
                        >
                          <source :src="src" type="video/mp4" />
                        </video>
                      </div>

                    </v-col>
                  </v-row>
                </div>
              </v-container>
              <v-overlay v-if="selectedItem">
                <video
                  width="1280"
                  height="720"
                  controls
                  aspect-ratio="1.7778"
                  @click="selectedItem = null"
                >
                  <source
                    :src="selectedItem ? selectedItem : ''"
                    type="video/mp4"
                  />
                </video>
              </v-overlay>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-icon dark color="warning" v-if="item.status == 1">
          {{ icons.mdiAlertCircle }}
        </v-icon>
        <v-icon ddark color="success" v-else-if="item.status == 0">
          {{ icons.mdiCheckCircle }}
        </v-icon>
      </template>
      <template v-slot:[`item.driverslicense_verified`]="{ item }">
        <v-icon dark color="error" v-if="item.driverslicense_verified == null">
          {{ icons.mdiAlertCircle }}
        </v-icon>
        <v-icon dark color="warning" v-if="item.driverslicense_verified == 0">
          {{ icons.mdiAlertCircle }}
        </v-icon>
        <v-icon
          ddark
          color="success"
          v-else-if="item.driverslicense_verified == 1"
        >
          {{ icons.mdiCheckCircle }}
        </v-icon>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          v-if="item.image_path != null && item.image_path != ''"
          class="mx-2"
          fab
          dark
          small
          rounded
          color="primary"
          @click="showImage(item, item.name)"
        >
          <v-icon dark>
            {{ icons.mdiCameraImage }}
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiCameraImage,
  mdiCheckCircle,
  mdiAlertCircle,
  mdiPencil,
  mdiClose,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline,
  mdiReload
} from "@mdi/js";
import { mapActions, mapGetters } from "vuex";
import FormDialog from "../../../layouts/components/FormDialog.vue";
export default {
  props: { // Add a prop to receive the name
    selectedName: {
      type: String,
      default: ''
    }
  },
  components: {
    //VueCoreVideoPlayer,
    FormDialog,
  },
  data() {
    return {
      name: "Führerscheinkontrollen",
      selectedItem: null,
      visible: false,
      icons: {
        mdiCameraImage,
        mdiCheckCircle,
        mdiAlertCircle,
        mdiPencil,
        mdiClose,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiReload
      },
      statuses: [
        {
          name: "Aktiv",
          value: 1,
        },
        {
          name: "Inaktiv",
          value: 0,
        },
        {
          name: "",
          value: 2,
        },
      ],
      licence_verifications: ["TRUE", "FALSE", ""],
      search: "",
      headers: [
        {
          text: "Medien",
          align: "start",
          filterable: false,
          value: "action",
        },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Telefon", value: "phone" },
        { text: "Label", value: "custom_label" },
        { text: "Trans-Nr", value: "trans_token" },
        { text: "Angelegt", value: "created_at" },
        { text: "Update", value: "updated_at" },
        { text: "Vorg. abgeschl.", value: "status" },
        { text: "FS valide", value: "driverslicense_verified" },
      ],
      currentItem: {
        id: null,
        name: "",
        email: "",
        custom_label: "",
        trans_token: "",
        status: 2,
        driverslicense_verified: null,
      },
      defaultItem: {
        id: null,
        name: "",
        email: "",
        custom_label: "",
        trans_token: "",
        status: 2,
        driverslicense_verified: null,
      },
      dialog: false,
      editmodal: false,
    };
  },
  computed: {
    ...mapGetters("main", ["getDriversLicenseData", "imageSources"]),
  },
  methods: {
    ...mapActions("main", [
      "loadDriversLicenseData",
      "updateDriversLicenseSession",
      "retrieveImageSources",
    ]),
    reloadPage() {
      window.location.reload();
    },
    updateLicense() {
      this.updateDriversLicenseSession(this.currentItem);
      this.$swal('Vorgang erfolgreich bearbeitet.');
    },
    showImage(item) {
  this.currentItem = { ...this.defaultItem, ...item }; // Simplified object assignment
  this.retrieveImageSources(item.id);
  this.dialog = true;
  this.selectedName = item.name; // Ensure the selectedName is updated with the item's name
},
    close() {
      this.dialog = false;
      this.image_sources = [];
      this.selectedItem = null;
      this.$nextTick(() => {
        this.currentItem = Object.assign({}, this.defaultItem);
      });
    },
      show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    changeVerification(status) {
      this.currentItem.driverslicense_verified =
        !this.currentItem.driverslicense_verified;

      if (status == true) {
        this.currentItem.driverslicense_verified = true;
      } else {
        this.currentItem.driverslicense_verified = false;
      }

      this.updateLicense();
    },
  },
  mounted() {
    this.$store.dispatch("main/loadDriversLicenseData");
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
};
</script>
